import styled from "styled-components"

import { FormikProps } from "formik"

import { CreateOrganizationStepWrapper } from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/organizationCreateWizardStyles"
import { breakpoint } from "src/constants/breakpoints"
import { TProfilingServices } from "src/data/analytics/types/analyticsTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCheckbox } from "src/ui/MCheckbox/MCheckbox"
import { MText } from "src/ui/MText"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export type TOrganizationServicesForm = {
  profilingServices: TProfilingServices[]
  customProfilingServices: string
}

export const ORG_SERVICES_FORM_ID = "org-services-form"

export function OrganizationServicesStep({
  formData,
}: {
  formData: FormikProps<TOrganizationServicesForm>
}) {
  const { t } = useTranslate()

  function handleCheck(value: TProfilingServices, checked: boolean) {
    if (checked) {
      formData.setValues((prev) => ({
        ...prev,
        profilingServices: [...prev.profilingServices, value],
      }))
    } else {
      formData.setValues((prev) => ({
        ...prev,
        profilingServices: prev.profilingServices.filter((v) => v !== value),
      }))
    }
  }

  const serviceCheckboxes = getCheckboxes(t)

  function renderCheckboxes(checkboxes: TServiceCheckbox[]) {
    const otherIsChecked = formData.values.profilingServices.includes("Other")

    return checkboxes.map((checkbox) => (
      <div key={checkbox.value}>
        <MCheckbox
          checked={formData.values.profilingServices.includes(checkbox.value)}
          onCheck={(checked) => handleCheck(checkbox.value, checked)}
          label={checkbox.label}
          inputProps={{ name: "profilingServices" }}
        />
        {checkbox.value === "Other" && otherIsChecked && (
          <div>
            <MTextField
              name="customProfilingServices"
              value={formData.values.customProfilingServices}
              onChange={(value) => {
                formData.setValues((prev) => ({
                  ...prev,
                  customProfilingServices: value,
                }))
              }}
              placeholder={`${t(
                langKeys.create_organization_service_custom_placeholder
              )} (${t(langKeys.input_optional)})`}
            />
          </div>
        )}
      </div>
    ))
  }

  return (
    <CreateOrganizationStepWrapper
      id={ORG_SERVICES_FORM_ID}
      onSubmit={formData.handleSubmit}
      noValidate
    >
      <MText variant="heading2">
        {t(langKeys.create_organization_what_type_of_services)}
      </MText>
      <CheckboxesWrapper>
        <Checkboxes>{renderCheckboxes(serviceCheckboxes.left)}</Checkboxes>
        <Checkboxes>{renderCheckboxes(serviceCheckboxes.right)}</Checkboxes>
      </CheckboxesWrapper>
      {formData.touched.profilingServices &&
        formData.errors.profilingServices && (
          <MBanner type="error">{formData.errors.profilingServices}</MBanner>
        )}
    </CreateOrganizationStepWrapper>
  )
}

type TServiceCheckbox = {
  value: TProfilingServices
  label: string
}

function getCheckboxes(t: TTranslateFunction): {
  left: TServiceCheckbox[]
  right: TServiceCheckbox[]
} {
  return {
    left: [
      {
        value: "Vacation rentals",
        label: t(langKeys.create_organization_service_vacation_rentals),
      },
      {
        value: "Multi-family residential",
        label: t(langKeys.create_organization_service_multi_family_residential),
      },
      {
        value: "Student housing",
        label: t(langKeys.create_organization_service_student_housing),
      },
      {
        value: "Social housing",
        label: t(langKeys.create_organization_service_social_housing),
      },
    ],
    right: [
      {
        value: "Senior living",
        label: t(langKeys.create_organization_service_senior_living),
      },
      {
        value: "Apartment hotel",
        label: t(langKeys.create_organization_service_apartment_hotel),
      },
      {
        value: "Hotel",
        label: t(langKeys.create_organization_service_hotel),
      },
      {
        value: "Other",
        label: t(langKeys.create_organization_service_other),
      },
    ],
  }
}

const CheckboxesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${spacing.M};

  @media (${breakpoint.mediumUp}) {
    grid-template-columns: 1fr 1fr;
  }
`

const Checkboxes = styled.div`
  display: grid;
  gap: ${spacing.L};
`

import { FormikErrors, useFormik } from "formik"

import { TOrganizationServicesForm } from "src/components/Organizations/OrganizationCreate/OnboardingCreateOrganizationWizard/OrganizationServicesStep/OrganizationServicesStep"
import { useTranslate } from "src/i18n/useTranslate"

export function useOrganizationServicesForm({
  initialValues,
  onSubmit,
}: {
  initialValues: TOrganizationServicesForm
  onSubmit: (values: TOrganizationServicesForm) => void
}) {
  const { t, langKeys } = useTranslate()

  const servicesForm = useFormik<TOrganizationServicesForm>({
    initialValues,
    validateOnBlur: true,
    validate: (values) => {
      const errors: FormikErrors<TOrganizationServicesForm> = {}
      if (values.profilingServices.length === 0) {
        errors.profilingServices = t(
          langKeys.create_organization_services_error_text
        )
      }
      return errors
    },
    onSubmit,
  })

  return servicesForm
}

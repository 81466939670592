import { FormikErrors, useFormik } from "formik"

import { TOrganizationPortfolioForm } from "src/components/Organizations/OrganizationCreate/OnboardingCreateOrganizationWizard/OrganizationPortfolioStep/OrganizationPortfolioStep"
import { useTranslate } from "src/i18n/useTranslate"

export function useOrganizationPortfolioSizeForm({
  initialValues,
  onSubmit,
}: {
  initialValues: TOrganizationPortfolioForm
  onSubmit: (values: TOrganizationPortfolioForm) => void
}) {
  const { t, langKeys } = useTranslate()

  const portfolioSizeForm = useFormik<TOrganizationPortfolioForm>({
    initialValues,
    validate: (values) => {
      const errors: FormikErrors<TOrganizationPortfolioForm> = {}
      if (!values.initialPortfolioSize) {
        errors.initialPortfolioSize = t(
          langKeys.create_organization_portfolio_size_error_text
        )
      }
      return errors
    },
    onSubmit,
  })

  return portfolioSizeForm
}

import { FormControlLabel, Radio } from "@material-ui/core"
import { FormikProps } from "formik"

import {
  CreateOrganizationStepWrapper,
  RadioButtonsWrapper,
} from "src/components/Organizations/OrganizationCreate/OrganizationCreateWizard/organizationCreateWizardStyles"
import { TInitialPortfolioSizes } from "src/data/analytics/types/analyticsTypes"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"

export type TOrganizationPortfolioForm = {
  initialPortfolioSize: TInitialPortfolioSizes | null
}

export const ORG_PORTFOLIO_FORM_ID = "org-portfolio-form"

export function OrganizationPortfolioStep({
  formData,
  isError = false,
}: {
  formData: FormikProps<TOrganizationPortfolioForm>
  isError?: boolean
}) {
  const { t, langKeys } = useTranslate()

  const sizeRadiobuttons = getRadioButtons(t)

  function renderRadiobuttons(radiobuttons: TPortfolioRadiobutton[]) {
    return radiobuttons.map((radioButton) => (
      <div key={radioButton.value}>
        <FormControlLabel
          value={radioButton.value}
          control={<Radio />}
          label={radioButton.label}
        />
      </div>
    ))
  }

  return (
    <CreateOrganizationStepWrapper
      id={ORG_PORTFOLIO_FORM_ID}
      onSubmit={formData.handleSubmit}
      noValidate
    >
      <MText variant="heading2">
        {t(langKeys.create_organization_how_many_units)}
      </MText>

      <RadioButtonsWrapper
        name="initialPortfolioSize"
        value={formData.values.initialPortfolioSize}
        onChange={formData.handleChange}
      >
        <div>{renderRadiobuttons(sizeRadiobuttons.left)}</div>
        <div>{renderRadiobuttons(sizeRadiobuttons.right)}</div>
      </RadioButtonsWrapper>
      {formData.touched.initialPortfolioSize &&
        formData.errors.initialPortfolioSize && (
          <MBanner type="error">{formData.errors.initialPortfolioSize}</MBanner>
        )}

      {isError && (
        <MBanner type="error">
          {t(langKeys.failed_something_went_wrong)}
        </MBanner>
      )}
    </CreateOrganizationStepWrapper>
  )
}

type TPortfolioRadiobutton = { value: TInitialPortfolioSizes; label: string }

function getRadioButtons(t: TTranslateFunction): {
  left: TPortfolioRadiobutton[]
  right: TPortfolioRadiobutton[]
} {
  return {
    left: [
      {
        value: "1-5 units",
        label: `1-5 ${t(langKeys.unit, {
          count: 2,
        })}`,
      },
      {
        value: "6-19 units",
        label: `6-19 ${t(langKeys.unit, {
          count: 2,
        })}`,
      },
      {
        value: "20-99 units",
        label: `20-99 ${t(langKeys.unit, {
          count: 2,
        })}`,
      },
    ],
    right: [
      {
        value: "100-499 units",
        label: `100-499 ${t(langKeys.unit, {
          count: 2,
        })}`,
      },
      {
        value: "500+ units",
        label: `500+ ${t(langKeys.unit, {
          count: 2,
        })}`,
      },
    ],
  }
}

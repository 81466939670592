import { TInitialPortfolioSizes } from "src/data/analytics/types/analyticsTypes"

import { usePostTrackEventWithParams } from "./analyticsQueries"

export type TAnalyticsEventsOther = "Initial Portfolio Size Reported"

export function usePostTrackIntialPortfoliosSize() {
  return usePostTrackEventWithParams<
    TAnalyticsEventsOther,
    { profiling_customer_size_segment: TInitialPortfolioSizes }
  >({
    event: "Initial Portfolio Size Reported",
  })
}
